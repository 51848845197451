var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-badge',{class:{'blink': _vm.reminder.blink === true},attrs:{"transition":"slide-x-transition","color":_vm.reminder.color,"overlap":"","offset-y":"12","content":_vm.reminder.label,"value":_vm.reminder.show}},[_c('div',{staticClass:"tile-order-number pt-1 d-flex flex-column align-center",class:{
      'tile-eat-in': _vm.type == 'eat_in',
      'tile-to-go': _vm.type == 'to_go',
      'tile-delivery': _vm.type == 'delivery',
    }},[_c('span',{staticClass:"subtitle font-weight-bold"},[_vm._v(_vm._s(_vm.mealTypeLiteral))]),_c('span',{staticClass:"font-weight-bold",class:{
        'display-1': _vm.number.length < 4, 'headline': _vm.number.length == 4,
        'title': _vm.number.length > 4
      }},[_vm._v(" "+_vm._s(_vm.number)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }