<template>
  <v-badge transition="slide-x-transition" :color="reminder.color" overlap
    offset-y="12" :content="reminder.label" v-bind:class="{'blink': reminder.blink === true}"
    :value="reminder.show">

    <div class="tile-order-number pt-1 d-flex flex-column align-center"
      v-bind:class="{
        'tile-eat-in': type == 'eat_in',
        'tile-to-go': type == 'to_go',
        'tile-delivery': type == 'delivery',
      }">

      <span class="subtitle font-weight-bold">{{ mealTypeLiteral }}</span>
      <span class="font-weight-bold"
        v-bind:class="{
          'display-1': number.length < 4, 'headline': number.length == 4,
          'title': number.length > 4
        }">
        {{ number }}
      </span>
      
    </div>
  </v-badge>
</template>

<script>
export default {
  name: 'OrderNumberTile',
  props: {
    number: String,
    type: String,
    status: String,
    
    reminder: {
      type: Object,
      default: () => {
        return { show: false };
      }
    }
  },

  data() {
    return {
      // reminder: {
      //   show: this.status === 'new',
      //   color: 'green',
      //   label: ' '
      // }
    };
  },

  computed: {
    mealTypeLiteral() {
      let mealTypeLabels = this.$store.getters.mealTypeLabels;
      return {
        'eat_in': mealTypeLabels[0],
        'to_go': mealTypeLabels[1],
        'delivery': mealTypeLabels[2]
      }[ this.type ];
    }
  }
}
</script>

<style scoped>
div.tile-order-number {
  position: relative;
  min-width: 64px;
  height: 64px;
  border: 1px solid;
  border-radius: 4px;
}
div.tile-eat-in {
  color: #2196F3;
}
div.tile-to-go {
  color: #FF9800; 
}
div.tile-delivery {
  color: #F06292; 
}


</style>

<style>
/* FIXME: This overwrites several "v-badge__badge" attributes. */
/* span.v-badge__badge {
  height: 40px;
  font-size: 24px;
  line-height: 24px;
  padding: 8px 6px;
  border-radius: 20px;
} */
/* Blinking effect */
.blink span.v-badge__badge {
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>